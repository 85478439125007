export default {
  message: {
    welcome: 'Hi，欢迎来到 ',
    login: '登录',
    logout: '退出',
    register: '注册',
    help: '帮助',
    shoppingCart: '购物车',
    notification: '消息通知',
    userfeedback: '意见反馈',
    merchantCenter: '商家店铺中心',
    merchantSettled: '商家入驻',
    serviceHotline: '服务热线',
    languages: '多语言',
    registerAccount: '注册账号',
    userLogin: '用户登录',
    userCenter: '用户中心'
  },
  category: {
    all: '全部分类',
    home: '首页',
    sourceCodeHall: '源码出售大厅',
    demandCenter: '需求任务大厅',
    serviceCenter: '技术服务大厅',
    ITPluralism: '兼职人才大厅',
    serviceProviderCenter: '服务商大厅',
    documentCenter: '技术文档大厅',
    designCenter: '设计原稿大厅',
  }
}
